<template>
  <div class="contain">
    <!-- 发病患病率-分公司-->
    <div class="form-header-btn">
      <div class="header-title">发病患病率列表</div>
      <div>
        <!-- <el-button
          type="primary"
          class="sheet-btn"
          @click="downLoadFile"
          v-if="isExport"
        >
          导出
        </el-button> -->
		<el-dropdown @command="handleCommand">
		  <el-button type="primary" style="margin-right: 0.52083vw;">
		    导出 <i class="el-icon-arrow-down el-icon--right"></i>
		  </el-button>
		  <el-dropdown-menu slot="dropdown">
		    <el-dropdown-item command="school">按园校</el-dropdown-item>
		    <el-dropdown-item command="all">全部</el-dropdown-item>
		  </el-dropdown-menu>
		</el-dropdown>
        <el-button
          type="primary"
          class="sheet-btn"
          @click="onCreatChart"
          v-if="isChart"
        >
          生成图表
        </el-button>
      </div>
    </div>

    <el-form :model="form" onsubmit="return false" :inline="true" class="search-form">
      <el-form-item>
        <el-select
          v-model="form.parkId"
          clearable
          placeholder="请选择园校"
          @change="handlePark"
        >
          <el-option
            v-for="item in moduleParkOptions"
            :key="item.label"
            :label="item.name"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-select
          v-model="form.classId"
          clearable
          placeholder="请选择班级"
          :disabled="disabledClass"
        >
          <el-option
            v-for="item in moduleClassOptions"
            :key="item.label"
            :label="item.name"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-date-picker
          type="month"
          placeholder="请选择日期"
          v-model="form.morbidityDate"
          value-format="yyyy-MM"
          style="width: 100%"
          :picker-options="pickerOptions"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="searchFun">
          查询
        </el-button>
      </el-form-item>

      <el-form-item>
        <el-button icon="el-icon-refresh-left" @click="resetFun">
          重置
        </el-button>
      </el-form-item>
    </el-form>

    <div class="change-open-level">
      <el-radio-group v-model="openLevel" @change="changeOpenLevel">
        <el-radio :label="0">展开一级</el-radio>
        <el-radio :label="2">展开全部</el-radio>
      </el-radio-group>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableData"
      :header-cell-style="{
        background: '#F1F4F9',
        color: '#5E6D9B',
        fontWeight: '400',
        'text-align':'center'
      }"
      v-loading="tableLoading"
      :expand-row-keys="expandRow"
      ref="tableData"
      :row-key="
        (row) => {
          return row.label;
        }
      "
      :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
      @sort-change="sortChange"
    >
      <el-table-column prop="name" label="园校" min-width="300">
      </el-table-column>
      <el-table-column prop="gradeName" label="年级" min-width="130" align="center">
      </el-table-column>
      <el-table-column
        prop="times"
        label="患病人数（次）"
        min-width="130"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="attendanceNumber"
        label="出勤人数（次）"
        min-width="130"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="sickenRate"
        label="患病率%"
        min-width="110"
        sortable="custom"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="num" label="发病人数" min-width="100" align="center">
      </el-table-column>
      <el-table-column prop="classNumber" label="班级幼儿数" min-width="120" align="center">
      </el-table-column>
      <el-table-column
        prop="rate"
        label="发病率%"
        min-width="120"
        sortable="custom"
        align="center"
      >
        <template slot-scope="{ row }">
          <span
            :class="
              row.rate >= parseFloat(row.targetRate)
                ? 'activeColor'
                : 'defaultColor'
            "
          >
            {{ row.rate }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="targetRate" label="目标发病率%" min-width="120" align="center">
      </el-table-column>
      <el-table-column label="操作" width="80" fixed="right" align="center">
        <template slot-scope="scope">
          <el-button
            @click="handleJumpDetail(scope.row)"
            type="text"
            size="medium"
            v-if="isView"
          >
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import downloadFile from "@/utils/downloadFile";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      tableLoading: false, // 表格loading
      form: {
        orgId: "",
        parkId: "",
        classId: "",
        morbidityDate: "",
      }, //表单

      formExport: {
        orgId: "",
        parkId: "",
        classId: "",
        morbidityDate: "",
      },
      moduleClassOptions: [], // 所属班级下拉
      moduleParkOptions: [], //所属园校下拉
      tableData: [], //表格数据
      attendanceDetailsList: [], //月天数
      disabledClass: true, //是否禁用class选择框
      expandRow: [],
      openLevel: 2,
      tYear: "",
      orglookId: "",
      parklookId: "",
      classlookId: "",
      permissionButtonList: [], // 权限按钮list
      isView: false,
      isExport: false,
      isChart: false,
      pickerOptions: {
        disabledDate(times) {
          return times.getTime() > Date.now();
        },
      },
    };
  },

  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
      userInfo: (state) => state.userInfo,
    }),
  },

  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isView = val.indexOf("f_morbidity:view") != -1;
          this.isExport = val.indexOf("f_morbidity:export") != -1;
          this.isChart = val.indexOf("f_morbidity:chart") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },

  created() {
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
  },

  mounted() {
    this.getDateNow();
    // this.init();
    this.getSchoolList();
  },
  activated(){
    this.init();
  },


  methods: {
    //排序
    sortChange(column) {
      // this.columnOrder = column.order;
      if (column.order == "descending") {
        this.init(column.prop, "2");
      } else if (column.order == "ascending") {
        this.init(column.prop, "1");
      }
    },

    getPermissionButton,
    getDateNow() {
      var myDate = new Date();
      var tYear = myDate.getFullYear();
      var tMonth = myDate.getMonth();
      var m = tMonth + 1;
      if (m.toString().length == 1) {
        m = "0" + m;
      }
      this.form.morbidityDate = tYear + "-" + m;
    },

    /** 初始化 */
    init(sortName, order) {
      let params = {
        orgId: this.form.orgId,
        parkId: this.form.parkId,
        classId: this.form.classId,
        morbidityDate: this.form.morbidityDate,
      };

      if (sortName === "sickenRate" && order == "2") {
        params.sickenRate = "2";
      } else if (sortName === "sickenRate" && order == "1") {
        params.sickenRate = "1";
      } else if (sortName === "rate" && order == "2") {
        params.rate = "2";
      } else if (sortName === "rate" && order == "1") {
        params.rate = "1";
      }
      this.tableLoading = true;
      this.$api.getMorbidityInfoByMonthForCom(params).then((res) => {
        if (res.data.code == "0") {
          this.tableData = res.data.data;
          setTimeout(() => {
            this.changeOpenLevel(4);
          }, 200);
          this.tableLoading = false;
        } else {
          this.$message.error(res.data.msg);
          this.tableLoading = false;
        }
      });
    },

    //查询园校
    getSchoolList(valCompany) {
      let params = {
        parent: valCompany,
        type: "NODE_SCHOOL",
      };
      this.$api.getCompanyDept(params).then((res) => {
        if (res.data.code == "0") {
          this.moduleParkOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查询分公司change事件
    // handleCompany(val) {
    //   if (val) {
    //     this.getSchoolList(val);
    //   }
    // },

    //查询园校change事件
    handlePark(val) {
      this.form.classId = "";
      if (val == "") {
        this.disabledClass = true;
      } else {
        this.disabledClass = false;
        this.getClassInfoList(val);
      }
    },

    //查询班级
    getClassInfoList(valPark) {
      let params = {
        parentLabel: valPark,
      };
      this.$api.getClassInfo(params).then((res) => {
        if (res.data.code == "0") {
          this.moduleClassOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
	// 按类型导出
	handleCommand(type){
		if(type == 'company'){
			this.downLoadFile(1)
		}
		else if(type == 'school'){
			this.downLoadFile(2)
		}
		else if(type == 'all'){
			this.downLoadFile(3)
		}
	},
    //导出
    downLoadFile(downFlag) {
      if (this.tableData == "") {
        this.$message.warning("暂无数据，不能导出！");
      } else {
		let userInfo = sessionStorage.getItem("userInfo")
		    ? JSON.parse(sessionStorage.getItem("userInfo"))
		    : {};
        //下载文件
        let url = "/mng/morbidity/morbidity/export";
        let data = {
          orgId: userInfo.schoolList[0].companyLabel,
          parkId: this.formExport.parkId,
          classId: this.formExport.classId,
          morbidityDate:
            this.formExport.morbidityDate || this.form.morbidityDate,
		  downFlag
        };

        let type = "get";
        this.tableLoading = true;
        downloadFile({ url, data, type }).then(() => {
          this.tableLoading = false;
        });
      }
    },

    //查看
    handleJumpDetail(row) {
      if (row.deptType == "org") {
        this.orglookId = row.label;
      } else if (row.deptType == "park") {
        this.parklookId = row.label;
      } else if (row.deptType == "class") {
        this.classlookId = row.label;
      }

      this.$router.push({
        path: "/mobidtyLoook",
        query: {
          deptType: row.deptType,
          orglookId: this.orglookId,
          parklookId: this.parklookId,
          classlookId: this.classlookId,
          morbidityDate: this.form.morbidityDate,
          comStatus: "2",
        },
      });
    },

    /** 生成图表 */
    onCreatChart() {
      if (this.tableData == "") {
        this.$message.warning("暂无数据，不能生成图表！");
      } else {
        var obj = this.form;
        obj.type = "2";
        this.$router.push({
          path: "/bringcharts",
          query: obj,
        });
      }
    },

    // 查询
    searchFun() {
      this.init();
      this.formExport = {
        orgId: this.form.orgId,
        parkId: this.form.parkId,
        classId: this.form.classId,
        morbidityDate: this.form.morbidityDate,
      };
    },

    // 重置
    resetFun() {
      this.form = {
        classId: "",
      };
	    this.getDateNow();
      this.init();
    },

    //展开一级
    changeOpenLevel(val) {
      this.resetTabDate();
      if (val == 2) {
        this.expandRow = [];
        this.tableData.map((item) => {
          this.expandRow.push(item.label);
          this.$refs.tableData.toggleRowExpansion(item, true);
        });
      } else if (val == 4) {
        this.expandRow = [];
        this.tableData.map((item) => {
          this.expandRow.push(item.label);
          this.$refs.tableData.toggleRowExpansion(item, true);
        });
      }
    },

    //收起展开菜单
    resetTabDate(arr) {
      this.expandRow = [];
      let data = arr || this.tableData;
      data.forEach((item) => {
        this.$refs.tableData.toggleRowExpansion(item, false);
        if (Object.prototype.toString.call(item.child) == "[object Array]") {
          this.resetTabDate(item.child);
        }
      });
    },

    //返回上一页
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";

.contain {
  padding: 26px 30px 30px 30px;

  .form-header-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .sheet-btn {
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }

  /deep/.el-table {
    // margin-top: 14px;
  }

  /deep/.el-table th > .cell {
    padding-left: 14px;
  }

  .change-open-level {
    display: flex;
    justify-content: flex-end;
    margin: 12px 0 28px 0;
  }
}
</style>
